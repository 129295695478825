<template>
  <div class="chats">
    <div
      v-for="(msgGrp, index) in formattedChatData.formattedChatLog"
      :key="msgGrp.senderId+String(index)"
      class="chat"
      :class="{'chat-left': msgGrp.senderId === formattedChatData.contact.id}"
    >
      <div class="chat-avatar">
        <b-avatar
            size="36"
            class="avatar-border-2 box-shadow-1"
            variant="transparent"
            :src="formattedChatData.avatar"
        />
      </div>
      <div class="chat-body">
        <div
          v-for="msgData in msgGrp.messages"
          :key="msgData.time"
          class="chat-content"
        >
          <p>{{ msgData.msg }}</p>

          <p style="font-size:12px;">{{ msgData.time}} </p>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import { BAvatar } from 'bootstrap-vue'

export default {
  components: {
    BAvatar,
  },
  props: {
    chatData: {
      type: Object,
      required: true,
    }
  },
  setup(props) {
    const formattedChatData = computed(() => {

      const contact = {
        id: props.chatData.recipient
      }

      let chatLog = []
      if (props.chatData.chats) {
        chatLog = props.chatData.chats
      }

      const formattedChatLog = []
      let chatMessageSenderId = chatLog[0] ? chatLog[0].sender : undefined
      let msgGroup = {
        sender: chatMessageSenderId,
        messages: [],
      }

      chatLog.forEach((msg, index) => {
        if (chatMessageSenderId === msg.sender) {
          msgGroup.messages.push({
            msg: msg.message,
            time: msg.dateTime,
          })
        } else {
          chatMessageSenderId = msg.sender
          formattedChatLog.push(msgGroup)
          msgGroup = {
            senderId: msg.sender,
            messages: [{
              msg: msg.message,
              time: msg.dateTime,
            }],
          }
        }
        if (index === chatLog.length - 1) formattedChatLog.push(msgGroup)
      })

      let avatar = require('@/assets/images/avatars/8.png');

      return {
        formattedChatLog,
        contact,
        avatar
      }
    })

    return {
      formattedChatData,
    }
  },
}
</script>

<style>

</style>
